import { useEffect, useState } from 'react';
import { getBooks } from '../services/apiService';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function useBookSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [books, setBooks] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    setBooks([])
  }, [query])

  useEffect(() => {
    const fetchBooks = async () => {
        setLoading(true);
        setError(false);

        const params = {page: pageNumber};
          if (query) {
              params.searchQuery = query;
          }
    
        const data = await getBooks(params);
    
        if (data.error) {
          if (axios.isCancel(data.error)) return; // If the request was canceled, do nothing
          setError(t('books.errorFetchingBooks'));
          setLoading(false);
          return;
        }
    
        if (data) {
          setBooks((prevBooks) => [...prevBooks, ...data.books]);
          setHasMore(data.hasMore);
          setLoading(false);
        }
    
        // Cleanup function to cancel the request if the component unmounts
        return () => data.cancelTokenSource && data.cancelTokenSource.cancel();
      };
    
    fetchBooks();
  
  }, [query, pageNumber, t]);

  return { loading, error, books, hasMore }
}