import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { validateSubmitQuestion } from '../utils/validation';
import { submitQuestion } from '../services/apiService';
import { ReactComponent as MessageSentIcon } from '../assets/icons/message-sent.svg';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as SubmitSuccessIcon } from '../assets/icons/submit-success.svg';
import styles from '../styles/pages/SubmitQuestion.module.css';

const SubmitQuestion = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
    question: ''
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [refNo, setRefNo] = useState(null);
  const [loading, setLoading] = useState(false);  // Loading state for form submission
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const errorRef = useRef(null);
    
  useEffect(() => {
      if (error && errorRef.current) {
          errorRef.current.focus();
      }
  }, [error]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleBackClick = () => {
    navigate('/'); // Navigate back to Home page
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);  // Set reCAPTCHA token when verified
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);  // Set loading to true when submission starts

    // Validate form data
    const validationError = validateSubmitQuestion(formData, recaptchaToken, t);
    if (validationError) {
      setError(validationError);
      setLoading(false);  // Stop loading if validation fails
      return;
    }

    try {
      const response = await submitQuestion(formData, recaptchaToken);
      setRefNo(response.refNo);
      setSuccess(true);
    } catch (error) {
      setError(t('submitQuestion.submissionError'));
    } finally {
      setLoading(false);  // Stop loading when submission is complete
    }
  };

  if (success) {
    return (
      <div>
        <div className="background-image-right-bottom-corner"></div>
        <div className="max-w-lg mx-auto bg-white bg-opacity-50 p-16 rounded-lg shadow-lg mt-10 text-center">
          <MessageSentIcon className="mx-auto mb-4 w-13 h-12" />
          <h2 className="text-3xl font-bold text-green-600 mb-4">{t('submitQuestion.successTitle')}</h2>
          <p className="text-gray-700 mb-2">{t('submitQuestion.successMessage')}</p>
          <p className="text-gray-700 mb-6">{t('submitQuestion.refNo')}: {refNo}</p>
          <button
            className="bg-gradient-green-r text-button-white font-bold py-2 px-6 rounded hover:bg-blue-600"
            onClick={handleBackClick}
          >
            {t('submitQuestion.backToHome')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.submitQuestionContainer} max-w-xl mx-auto py-10 px-20 rounded-lg shadow-lg mt-10`}>
      <div className="background-image-right-bottom-corner"></div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">{t('submitQuestion.title')}</h2>
      {error && (
        <p 
            ref={errorRef} 
            tabIndex="-1" 
            className="text-red-500 mb-4" 
            aria-live="assertive"  // Inform assistive technologies about changes
        >
            {error}
        </p>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">{t('submitQuestion.firstNameLabel')}:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-gray-700">{t('submitQuestion.lastNameLabel')}:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
          <div className="input-group">
            <label className="block text-gray-700">{t('submitQuestion.phoneLabel')}</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
            />
          </div>
        <div>
          <label className="block text-gray-700">{t('submitQuestion.titleLabel')}:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700">{t('submitQuestion.questionLabel')}:</label>
          <textarea
            name="question"
            value={formData.question}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
          ></textarea>
        </div>

        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={handleRecaptchaChange}
        />

        <button
          type="submit"
          className="w-full py-2 px-4 bg-gradient-green-r text-button-white font-semibold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          disabled={loading}  // Disable button when loading
        >
          {loading ? (
            <span className="flex justify-center items-center">
              <SpinnerIcon className={`animate-spin w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
              {t('submitQuestion.submitting')}
            </span>
          ) : (
            <span className="flex justify-center items-center">
              <SubmitSuccessIcon className={`w-5 h-5 ${i18n.language === "ar" ? "ml-2" : "mr-2"}`} /> 
              {t('submitQuestion.submitButton')}
            </span>
          )}
        </button>
      </form>
    </div>
  );
};

export default SubmitQuestion;
