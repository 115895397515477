import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LanguagesIcon } from '../assets/icons/languages.svg';
import styles from '../styles/components/LanguageSwitcher.module.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [isDropdownOpen, setDropdownOpen] = useState(false)

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        setDropdownOpen(false);
      };
    
    const languages = [
        { code: 'ar', name: 'العربية' },
        { code: 'tzm', name: 'ⵜⴰⵎⴰⵣⵉⵖⵜ' },
        { code: 'en', name: 'English' },
        { code: 'fr', name: 'Français' },
    ];

  return (
    <section className={`${styles.languageSwitcher} bg-green-50`}>
      {/* Regular language buttons for larger screens */}
      <ul className="hidden md:flex justify-start">
        {languages.map((language) => (
          <li key={language.code}>
            <button
              className={`${styles.languageButton} p-5 hover:text-medium-green hover:bg-white ${
                i18n.language === language.code && (isArabic ? 'bg-gradient-green-r text-white' : 'bg-gradient-green-l text-white')
              }`}
              onClick={() => handleLanguageChange(language.code)}
            >
              {language.name}
            </button>
          </li>
        ))}
      </ul>

      {/* Dropdown selector for mobile devices */}
      <div className="md:hidden relative">
        <button
          className="dropdown-toggle flex items-center px-4 py-2"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
        >
          <LanguagesIcon style={{ width: '20px', height: '20px' }} />
          <span className={`${isArabic ? 'mr-2' : 'ml-2'}`}>{languages.find((language) => language.code === i18n.language)?.name || 'English'}</span>
        </button>
        {isDropdownOpen && (
          <ul className={`absolute ${isArabic ? 'right-0' : 'left-0'} mt-2 w-[50%] bg-white shadow-lg rounded-lg z-10`}>
            {languages.map((language) => (
              <li key={language.code}>
                <button
                  className="w-full text-center px-4 py-2 hover:bg-gray-100"
                  onClick={() => handleLanguageChange(language.code)}
                >
                  {language.name}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>

    
  );
};

export default LanguageSwitcher;
