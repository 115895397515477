import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFatwas } from '../services/apiService';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function useFatwaSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [fatwas, setFatwas] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const { t } = useTranslation();
  const location = useLocation();

  // Parse query parameters from the URL
  const params = new URLSearchParams(location.search);
  const categoryId = params.get('categoryId');
  const searchQuery = params.get('searchQuery');
  const refNo = searchQuery ? null : params.get('refNo');


  useEffect(() => {
    setFatwas([])
  }, [query])

  useEffect(() => {
    const fetchFatwas = async () => {
        setLoading(true);
        setError(false);

        const params = {page: pageNumber};
        if (categoryId) {
          params.categoryId = categoryId;
        }
        
        if (query) {
          params.searchQuery = query;
        } else if (refNo) {
          params.refNo = refNo;
        }
    
        const data = await getFatwas(params);
    
        if (data.error) {
          if (axios.isCancel(data.error)) return; // If the request was canceled, do nothing
          setError(t('fatwaEncyclopedia.errorFetchingFatwas'));
          setLoading(false);
          return;
        }
    
        if (data) {
          setFatwas((prevFatwas) => [...prevFatwas, ...data.fatwas]);
          setHasMore(data.hasMore);
          setLoading(false);
        }
    
        // Cleanup function to cancel the request if the component unmounts
        return () => data.cancelTokenSource && data.cancelTokenSource.cancel();
      };
    
    fetchFatwas();
  
  }, [query, pageNumber, t, categoryId, refNo]);

  return { loading, error, fatwas, hasMore, searchQuery }
}